import React from 'react'
import facebookImage from '../img/social/facebook.svg'
import instagramImage from '../img/social/instagram.svg'
import twitterImage from '../img/social/twitter.svg'
import PropTypes from 'prop-types'

class SocialMediaLink extends React.Component {
  render() {
    const { links, facebook, instagram, twitter } = this.props
    return (
      <>
        {links.facebook && facebook(links.facebook)}
        {links.instagram && instagram(links.instagram)}
        {links.twitter && twitter(links.twitter)}
      </>
    )
  }
}

SocialMediaLink.defaultProps = {
  facebook: (facebook) => (
    <a
      title="facebook"
      href={`https://facebook.com/${facebook}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={facebookImage}
        alt="Facebook"
        style={{ width: '1em', height: '1em' }}
      />
    </a>
  ),
  instagram: (instagram) => (
    <a
      title="instagram"
      href={`https://instagram.com/${instagram}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={instagramImage}
        alt="Instagram"
        style={{ width: '1em', height: '1em' }}
      />
    </a>
  ),
  twitter: (twitter) => (
    <a
      title="twitter"
      href={`https://twitter.com/${twitter}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className="fas fa-lg"
        src={twitterImage}
        alt="Twitter"
        style={{ width: '1em', height: '1em' }}
      />
    </a>
  ),
}

SocialMediaLink.propTypes = {
  links: PropTypes.shape({
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    twitter: PropTypes.string,
  }),
}

export default SocialMediaLink
