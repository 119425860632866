import { useEffect } from 'react'

const useClickOutside = (ref, callback, isActive = true) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target) && isActive) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [handleClick])
}

export default useClickOutside
