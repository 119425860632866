import React, { useContext, useState } from 'react'
import { Link } from 'gatsby'
import CartPreview from './CartPreview'
import {
  CartStateContext,
  CartDispatchContext,
  toggleCartPopup,
} from '../contexts/Cart'

import logo from '../img/logo-t-3.jpg'
import bagImage from '../img/bag.png'
import { formatPrice } from '../../utils/stripe-handle'
import { useMediaQuery } from '../hooks/useMediaQuery'

const Navbar = (props) => {
  const isDesktop = useMediaQuery('(min-width: 800px)')
  const [activeNavBar, setActiveNavBar] = useState(isDesktop ? 'is-active' : '')
  const toggleHamburger = () => {
    setActiveNavBar(activeNavBar ? '' : 'is-active')
  }
  const { items } = useContext(CartStateContext)
  const cartDispatch = useContext(CartDispatchContext)
  const cartQuantity = items.length
  const cartTotal = items
    .map((item) => item.price * item.quantity)
    .reduce((prev, current) => prev + current, 0)

  const handleCartButton = (event) => {
    event.preventDefault()
    return toggleCartPopup(cartDispatch)
  }

  return (
    <nav
      className="navbar is-transparent"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="FuckRotary" style={{ width: '136px' }} />
            </Link>
            {/* eslint-disable */}
            <div
              className={`navbar-burger burger ${activeNavBar} is-clickable`}
              data-target="navMenu"
              onClick={() => toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
        </div>
        <div id="navMenu" className={`navbar-menu ${activeNavBar}`}>
          <div className="navbar-start">
            <Link className="navbar-item" to="/store">
              Store
            </Link>
            <Link className="navbar-item" to="/contact">
              Contact
            </Link>
            <Link className="navbar-item" to="/">
              About
            </Link>
          </div>
          <div className="navbar-end cart navbar-item">
            <div className="cart-info">
              <table>
                <tbody>
                  <tr className="has-text-weight-bold has-text-black">
                    <td>Items</td>
                    <td>:</td>
                    <td>
                      <strong>{cartQuantity}</strong>
                    </td>
                  </tr>
                  <tr className="has-text-weight-bold has-text-black">
                    <td>Total</td>
                    <td>:</td>
                    <td>
                      <strong>{formatPrice(cartTotal)}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <a className="cart-icon" href="#" onClick={handleCartButton}>
              <img src={bagImage} alt="Cart" />
              {cartQuantity ? (
                <span className="cart-count">{cartQuantity}</span>
              ) : (
                ''
              )}
            </a>
            <CartPreview />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
