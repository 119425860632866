import React from 'react'
import classNames from 'classnames'
import { toggleCartPopup } from '../contexts/Cart'
import { performPurchase } from '../../utils/stripe-handle'
import PropTypes from 'prop-types'

function toLineItems({ id, quantity }) {
  return {
    price: id,
    quantity,
  }
}
const Checkout = ({ items, dispatch }) => {
  const handleProceedCheckout = async () => {
    if (items && items.length === 0) {
      toggleCartPopup(dispatch)
      return
    }
    const line_items = items.map(toLineItems)

    await performPurchase(line_items)
    toggleCartPopup(dispatch)
  }

  return (
    <div className="action-block">
      <button
        type="button"
        className={`checkout ${classNames({
          disabled: items && items.length === 0,
        })}`}
        onClick={handleProceedCheckout}
      >
        PROCEED TO CHECKOUT
      </button>
    </div>
  )
}

Checkout.prototype = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      quantity: PropTypes.number,
      name: PropTypes.string,
      price: PropTypes.number,
      currency: PropTypes.string,
    })
  ),
}

export default Checkout
