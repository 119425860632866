import React, { useContext, useRef } from 'react'
import classNames from 'classnames'
import {
  CartStateContext,
  CartDispatchContext,
  removeFromCart,
  toggleCartPopup,
} from '../contexts/Cart'
import Checkout from './Checkout'

import { formatPrice } from '../../utils/stripe-handle'
import useClickOutside from '../hooks/useClickOutside'

const CartPreview = () => {
  const { items, isCartOpen } = useContext(CartStateContext)
  const dispatch = useContext(CartDispatchContext)

  const handleRemove = (productId) => {
    return removeFromCart(dispatch, productId)
  }

  const ref = useRef()
  useClickOutside(
    ref,
    () => {
      toggleCartPopup(dispatch)
    },
    isCartOpen
  )

  return (
    <div
      ref={ref}
      className={classNames('cart-preview', { active: isCartOpen })}
    >
      <ul className="cart-items">
        {items.map((product) => {
          return (
            <li className="cart-item" key={product.name}>
              <div className="product-info">
                <p className="product-name">{product.name}</p>
                <p className="product-price">{formatPrice(product.price)}</p>
              </div>
              <div className="product-total">
                <p className="amount">
                  {formatPrice(product.quantity * product.price)}
                </p>
              </div>

              <div style={{ padding: '3px 4px 3px', margin: '2px 3px 2px' }}>
                <button
                  onClick={() => handleRemove(product.id)}
                  className="delete"
                />
              </div>
            </li>
          )
        })}
      </ul>
      <Checkout items={items} dispatch={dispatch} />
    </div>
  )
}

export default CartPreview
