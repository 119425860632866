import getStripe from "./stripejs";

const addProductPrice = async body => {
  try {
    const response = await authFetch("/api/create-product", {
      body
    });
    return response.json();
  } catch (error) {
    console.error(error);
  }
};

const deleteProduct = async body => {
  try {
    const response = await authFetch("/api/delete-product", {
      body
    });
    return response.json();
  } catch (error) {
    console.error(error);
  }
};

const fetchProducts = async () => {
  const { data, error } = await fetch("/api/list-products", {
    headers: { "Content-Type": "application/json" }
  })
    .then(response => response.json())
    .catch(error => console.error(error));

  if (error) {
    console.error(error);
    return;
  }

  return data;
};


const authFetch = async (url, options) => {
  const user = JSON.parse(localStorage.getItem("gotrue.user"))

  const headers = {
    Authorization: `Bearer ${user.token.access_token}`
  };
  try {
    return await fetch(url, {
      method: "POST",
      ...options,
      headers
    })
  } catch (error) {
    console.error(error)
  }
};

const performPurchase = async payload => {
  fetch("/api/create-session", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(async response => {
      const { id } = await response.json();

      const stripe = await getStripe();
      const { error } = await stripe.redirectToCheckout({ sessionId: id });
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
      alert(error.message);
    })
    .catch(err => alert(err.message))
    .finally(() => {
      localStorage.setItem("cartItems", "[]");
    });
};

const formatPrice = (amount, currency = "usd") => {
  let price = (amount / 100).toFixed(2);
  let numberFormat = new Intl.NumberFormat(["en-US"], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol"
  });
  return numberFormat.format(price);
};

const extractTypes = data => {
  const products = {};
  const prices = {};
  data.forEach(price => {
    const { id } = price.product;
    if (!products[id]) {
      products[id] = { ...price.product, prices: [] };
    }
    products[id].prices.push(price);
    prices[price.id] = price;
  });
  return { products, prices };
};

export {
  fetchProducts,
  addProductPrice,
  deleteProduct,
  formatPrice,
  performPurchase
};
